import React from 'react';
// import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import LeftSideNav from "../components/LeftSideNav";


const PrivateLayout = ({children,pathname,hide}) => {
    const profileData = useSelector(state => state.auth.userData);
    return ( 
        <div className="w-full flex bg-white">
            <LeftSideNav/>
            <div className="w-10/12 h-screen bg-gray2 layout overflow-y-scroll  largeTablet:w-11/12 phone:w-full ">
                {children}
            </div>
        </div>
     );
}

// PrivateLayout.propTypes={
//     children: PropTypes.elementType.isRequired,
//     pathname: PropTypes.string
// };
 
export default PrivateLayout;