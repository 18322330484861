/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Redirect, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import history from "../helpers/history";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PrivateLayout from "../layout/PrivateLayout";

const AllRoutes = (props) => {
  const Login = lazy(() => import("../pages/auth/login"));
  const Dashboard = lazy(() => import("../pages/dashboard"));
  const Users = lazy(() => import("../pages/users"));
  const SingleUser = lazy(() => import("../pages/users/SingleUser"));
  const Events = lazy(() => import("../pages/events"));

  return (
    <>
    <Suspense fallback={<div>loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Users/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:id"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <SingleUser/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Events/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/causes"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/groups"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/marketplace"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/trybers"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admins"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <Dashboard/>
                </PrivateLayout>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
    </>
  );
};

export default AllRoutes;