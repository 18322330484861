const BASE_URL = {
    production: "http://ec2-13-245-209-129.af-south-1.compute.amazonaws.com:3004",
    staging: "http://ec2-13-245-209-129.af-south-1.compute.amazonaws.com:3004",
  };
  
  export const MIXPANEL_KEY = {
    staging: process.env.REACT_APP_MIXPANEL_KEY_STAGING,
    production: process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION
  };
  
  export default BASE_URL;