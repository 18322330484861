import React, { Component } from "react";
// import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 0 }}>
          <h1 className="sr">Trybe One</h1>
          <div className="site-wrapper">
            <main className="site-main full-page flx-center-center py-50">
              <section className="maxwidth-xsm w-100p wrapper--x-5">
                <section className="tx-c">
                  <h2 className="f-21 mb-20">
                    Something went wrong{" "}
                    <span role="img" aria-label="broken">
                      💔
                    </span>
                  </h2>
                  <p className="f-16 co-codegray lh-22">
                    refresh this page or check your internet connection <br />
                  </p>
                  <div className="mt-20">
                    <button
                      className="btn btn--standard"
                      onClick={() => window.location.replace("/")}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;GO HOME&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                  </div>
                </section>
              </section>
            </main>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// ErrorBoundary.propTypes = {
//   children: PropTypes.elementType.isRequired
// };

export default ErrorBoundary;
