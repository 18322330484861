import { toastError } from "../components/Toast";

const handleError = (err) => {
  if (err && err.response) {
    if (err.response.status === 500 || err.response.status === 502) {
      toastError("It's not you, it's us. Please try again after some time");
    } else {
      const error =
        err.response.data?.error ||
        err.response.data?.message ||
        err.response.data?.err ||
        (err.response.data?.data && err.response.data.data?.responseMessage);

      toastError(error);
    }
  } else if (err.message === "Network Error") {
    toastError("It's not you, it's us. Please try again after some time");
  }
};

export default handleError;
