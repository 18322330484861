// import localStorage from "redux-persist/es/storage";
// import { history } from "../../utils/history";
import { LOGOUT_USER, LOGGED_IN_USER } from "../types/authTypes";
import { IS_LOADING_TRUE, IS_LOADING_FALSE } from "../types/loadingTypes";
// import { setCookie, removeCookie } from "../../utils/storage";
import handleError from "../../utils/handleError";
import { postApi } from "../../utils/reqClient";
// import { toastSuccess } from "../../components/Toast";

export const login = (params, Navigate) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`login`, params);
    if (response.data.status === "success") {
      const { data } = response.data;
      localStorage.setItem("_trybe1_", data.token);
      dispatch({ type: LOGGED_IN_USER, payload: data.user });
      dispatch({ type: IS_LOADING_FALSE });
      return Navigate.to("/dashboard");
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const signOut = () => (dispatch) => {
  // Set global loading to false
  dispatch({ type: IS_LOADING_FALSE });
  localStorage.removeItem("_trybe1_");
  localStorage.removeItem("persist:root");
  dispatch({ type: LOGOUT_USER });
  // history.replace("/");
};