import React from "react";
import {useNavigate,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ErrorBoundary from "../components/ErrorBoundary";


const PrivateRoute = ({ children,isAuthenticated, }) => {
  let location = useLocation();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return     navigate("/");
    // <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};
// const PrivateRoute = ({
//   component: PrivateComponent,
//   isAuthenticated,
//   ...rest
// }) => {
//   const location = useLocation();
//   return (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated ? (
//         // if user is authenticated show request component
//         <ErrorBoundary>
//           <PrivateComponent {...props} />
//         </ErrorBoundary>
//       ) : (
//         // enable this line below to make route protected
//         <Navigate to="/" state={{ from: location }} replace />
//       )
//     }
//   />
// )};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.object
};

const mapSateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapSateToProps, {})(PrivateRoute);
