import {useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/logo.jpg";
import DummyUser from "../../assets/dummyUser.jpg";
import Icon from "../Icons";
import { signOut } from "../../redux/actions/authAction";

const LeftSideNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const activeLink = pathname.split("/")[1];
//   const responsiveNav = useSelector((state) => state.loading.responsiveNav);
  const handleActiveLink = (param) => {
    if (param === activeLink) {
      return "active w-full pl-8 pr-12";
    }
    return "w-full pl-8 pr-12"
  };
  
  const navList = [
    { name: "Dashboard", iconId: "dashboard-icon", tab: "/dashboard", pathName: "dashboard"},
    { name: "Users", iconId: "users-icon", tab: "/users", pathName: "users"},
    { name: "Events", iconId: "events-icon", tab: "/events", pathName: "events"},
    { name: "Causes", iconId: "causes-icon", tab: "/causes", pathName: "causes" },
    { name: "Groups", iconId: "profile-2user-icon", tab: "/groups", pathName: "groups"},
    { name: "Marketplace", iconId: "marketplace-icon", tab: "/marketplace", pathName: "marketplace"},
    { name: "Trybers", iconId: "profile-2user-icon", tab: "/trybers", pathName: "trybers"},
    { name: "Admins", iconId: "profile-2user-icon", tab: "/admins", pathName: "admins"},
  ];

  return (
      <div className=" w-250 left-nav" >
        <img className="mb-16 w-150 h-10 mt-10 object-contain m-a" src={Logo} />
        <ul className="w-full">
          {navList.map((navLink, i) => {
            return (
                <Link to={navLink.tab}>
                    <li key={i} className={handleActiveLink(navLink.pathName)} >
                        <Icon
                            className="mr-2"
                            id={
                            navLink.pathName === activeLink
                                ? `${navLink.iconId}2`
                                : navLink.iconId
                            }
                            width="24px"
                            height="24px"
                        />
                        <span>{navLink.name}</span>
                    </li>
                </Link>
            );
          })}
          <li className="flex pl-6 pt-2 mb-8">
            <img className=" w-10 h-10 object-contain object-center mr-2" src={DummyUser} />
            <aside>
                <h1 className=" text-sm text-black font-semibold">Emeka Anyanwu</h1>
                <p className=" font-medium text-gray-400">Super Admin</p>
            </aside>
          </li>
          <Link to="/">
              <li className={handleActiveLink("logout")} onClick={() => signOut()(dispatch)} >
                  <Icon
                      className="mr-2"
                      id="logout-icon"
                      width="24px"
                      height="24px"
                  />
                  <span>Log out</span>
              </li>
          </Link>
        </ul>
      </div>
  );
};

export default LeftSideNav;