import React from "react";
import { useDispatch } from "react-redux";
import AllRoutes from "./config/routes";
import {signOut} from "./redux/actions/authAction";
import './styles/main.css';
import './App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  const dispatch = useDispatch();
  const logout = () => {
    signOut()(dispatch);
  };

  const inactivityTime = () => {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeyup = resetTimer;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 18000000);
      // 1000 milliseconds = 1 second
    }
  };
  window.onload = function () {
    inactivityTime();
  };
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;